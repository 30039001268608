import React from 'react'
import {Button, Modal, ModalBody, Row,Col,CardBody, Media ,Input} from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import './styleM.css';


export const ModalTypeService = (props) => {

    const {modalAurhorization,toggleAutorization,handleInputChangeMethod,user_method,user_password_method,validatePasswordTypeService,loadingTypeService} = props;
    
    return (
        <Modal isOpen={modalAurhorization} toggle={toggleAutorization} color='danger' backdrop='static' keyboard={false} centered={true}>
           
                <ModalBody>   
                <CardBody className=" pt-0 socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                 <Media> <br /> <br /><Media className="img-100 img-fluid m-r-20 rounded-circle" src={require("../../assets/images/loginHelenLabs/User/UserWoman.svg")} alt="" />
                                <Media body>
                                    <Row>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-user"> </i><span className="px-2">{"Usuario"}
                                        <Input
                                        type="text"
                                        name="user_method"
                                       value={user_method}
                                        required={true}
                                        autoComplete="nope"
                                       onChange={handleInputChangeMethod}
                                        className={`form-control form-control-sm input-air-primary border-primary`}
                                    />
                                    </span></span></span>
                                        </Col>
                                        <Col sm="6">
                                        <h6 className="font-primary f-w-600">{""}</h6><span className="d-block"><span><i className="icofont icofont-key"> </i><span className="px-2">{"Contraseña"}
                                        <Input
                                            type="password"
                                            name="user_password_method"
                                            value={user_password_method}
                                            required={true}
                                            autoComplete="nope"
                                            onChange={handleInputChangeMethod}
                                            className={`form-control form-control-sm input-air-primary border-primary`}
                                        />
                                        </span></span></span>
                                        </Col>
                                    </Row>
                                  
   
                                </Media>
                        </Media>
                       
                </CardBody>  
                    <Col sm="12">
                    <Row>
                    <Col sm="8" className='p-r-0' style={{textAlign:"end"}}>
                    <Button  color='danger' onClick={toggleAutorization}  >Cancelar</Button>
                    </Col>
                    <Col sm="4" className='p-r-0' onClick={ () => validatePasswordTypeService()} style={{textAlign:"end"}}>
                    <Button  color='success'>Autorizar</Button>
                    </Col>
                    </Row>
                    </Col> 
                </ModalBody>
          
        </Modal>
    )
}
